import {
  LOGIN_REF,
  REGISTER_REF,
  SIGNUP_SOURCE_LOCAL_STORAGE_KEY,
} from '@common/constants'
import { HOMEPAGE_SEGMENT_ENUM } from '@common/enums'
import LazyRecaptcha from '@components/common/lazy-recaptcha'
import { requestLoginOtp } from '@containers/auth/utils'
import { validatePhone } from '@containers/common/validators'
import useDetectCountry from '@hooks/useDetectCountry'
import useLazyRecaptcha from '@hooks/useLazyRecaptcha'
import useResolution from '@hooks/useResolution'
import { setToLocalStorage } from '@lib/utils/local-storage'
import { checkSupportedDevice } from '@lib/utils/system-check'
import { t } from '@lingui/macro'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import PhoneInput from 'react-phone-input-2'

const anonymousUserSegmentData = {
  device: checkSupportedDevice().device,
  timestamp: Date.now(),
  userType: 'none',
}

const { TRACK_BOOK_FREE_CLASS_FIRST_FOLD_CTA_CLICK } = HOMEPAGE_SEGMENT_ENUM

type PhoneContainerProps = {
  buttonText?: string
  countryCode?: string
  isAutoFocus?: boolean
  isInHomePage?: boolean
  isButtonBottom?: boolean
  isErrorDarkRed?: boolean
  shouldHaveBoxShadow?: boolean
  isInputFieldBorderGrey?: boolean
  isInputFieldWidthFixed?: boolean
  courseQuery?: string
}
export const PhoneInputContainer = ({
  buttonText = t`Try a free class`,
  isButtonBottom = false,
  isInputFieldBorderGrey = false,
  isInputFieldWidthFixed: isFixedInputFieldWidth = false,
  isErrorDarkRed = false,
  isAutoFocus = false,
  isInHomePage = false,
  countryCode,
  shouldHaveBoxShadow,
  courseQuery,
}: PhoneContainerProps) => {
  const [phoneInputData, setPhoneInputData] = useState({
    // User form inputs
    phone: '',

    // Server response
    response: {},
    errors: {},
    loading: false,
  })
  const {
    recaptchaAPI,
    setRecaptchaAPI,
    shouldRecaptchaLoad,
    setShouldRecaptchaLoad,
  } = useLazyRecaptcha()
  const router = useRouter()

  const query = useMemo(() => {
    const { query } = router
    return { phone: phoneInputData.phone, ...query }
  }, [phoneInputData.phone, router.query])

  const changePhoneInputData = (data) => {
    setPhoneInputData((prevData) => ({ ...prevData, ...data }))
    setShouldRecaptchaLoad(true)
  }

  const handlePhoneInputChange = (value: string) => {
    changePhoneInputData({
      phone: value,
    })
  }

  const handlePhoneInputFormSubmit = async () => {
    if (phoneInputData.loading || !recaptchaAPI) return

    changePhoneInputData({
      loading: true,
    })

    const errors = validatePhone(phoneInputData.phone)
    if (Object.keys(errors).length) {
      changePhoneInputData({
        errors,
        loading: false,
      })
      return
    }

    const formBody = {
      phone: `+${phoneInputData.phone}`,
    }

    router.asPath.split('?')[0] === '/' &&
      window.globalThis?.analytics?.track(
        TRACK_BOOK_FREE_CLASS_FIRST_FOLD_CTA_CLICK,
        anonymousUserSegmentData
      )

    const result = await requestLoginOtp(formBody)
    const queryParameters = courseQuery
      ? {
          ...query,
          source: result.status === 204 ? REGISTER_REF : LOGIN_REF,
          course: courseQuery,
        }
      : {
          ...query,
          source: result.status === 204 ? REGISTER_REF : LOGIN_REF,
        }
    if (result.status === 204) {
      // Account does not exist, continue with registration

      // set signup source
      setToLocalStorage(SIGNUP_SOURCE_LOCAL_STORAGE_KEY, router.asPath, () => {
        console.error(`Unable to store signup source ${router.asPath}`)
      })

      router.push({
        pathname: '/register/',
        query: { ...queryParameters },
      })
      changePhoneInputData({
        errors: {},
        loading: false,
      })
    } else if (result.status == 200) {
      // Account exists, send to login page
      router.push({
        pathname: '/login/verify-otp/',
        query: { ...queryParameters },
      })

      changePhoneInputData({
        errors: {},
        loading: false,
      })
    } else if (result.status === 429) {
      // If user closes the recaptcha challenge, the CTA is stuck in
      // loading state. Setting loading to false prevents this.
      changePhoneInputData({
        loading: false,
      })
      await recaptchaAPI.execute()
    } else {
      const errors = await result.json()
      changePhoneInputData({
        errors: errors,
        loading: false,
      })
    }
  }

  const handleCaptcha = async (key: string) => {
    if (!key) return

    changePhoneInputData({
      loading: true,
    })

    const formBody = {
      phone: `+${phoneInputData.phone}`,
      recaptchaResponse: key,
    }
    recaptchaAPI.reset()

    const result = await requestLoginOtp(formBody)
    if (result.status === 204) {
      // Account does not exist, continue with registration
      router.push({
        pathname: '/register/',
        query: {
          ...query,
          source: REGISTER_REF,
        },
      })
      changePhoneInputData({
        errors: {},
        loading: false,
      })
    } else if (result.status == 200) {
      // Account exists, send to login page
      router.push({
        pathname: '/login/verify-otp/',
        query: {
          ...query,
          source: LOGIN_REF,
        },
      })

      changePhoneInputData({
        errors: {},
        loading: false,
      })
    } else if (result.status === 406) {
      changePhoneInputData({
        errors: { token: [t`Error verifying reCAPTCHA, please try again`] },
        loading: false,
      })
    } else {
      const errors = await result.json()
      changePhoneInputData({
        errors: errors,
        loading: false,
      })
    }
  }

  const { phone, errors } = phoneInputData

  return (
    <>
      <PhoneInputComponent
        phone={phone}
        errors={errors}
        buttonText={buttonText}
        isButtonBottom={isButtonBottom}
        isErrorDarkRed={isErrorDarkRed}
        isInputFieldBorderGrey={isInputFieldBorderGrey}
        isInputFieldWidthFixed={isFixedInputFieldWidth}
        handlePhoneInputChange={handlePhoneInputChange}
        handlePhoneInputFormSubmit={handlePhoneInputFormSubmit}
        isAutoFocus={isAutoFocus}
        isInHomePage={isInHomePage}
        countryCode={countryCode}
        shouldHaveBoxShadow={shouldHaveBoxShadow}
      />
      <LazyRecaptcha
        onChange={handleCaptcha}
        setRecaptchaAPI={setRecaptchaAPI}
        shouldRecaptchaLoad={shouldRecaptchaLoad}
      />
    </>
  )
}

type PhoneInputProps = PhoneContainerProps & {
  phone: string
  errors: any
  handlePhoneInputChange: (value: string) => void
  handlePhoneInputFormSubmit: () => void
}
export const PhoneInputComponent = ({
  phone = '',
  handlePhoneInputChange,
  handlePhoneInputFormSubmit,
  errors,
  buttonText,
  isErrorDarkRed,
  isInputFieldBorderGrey,
  isInputFieldWidthFixed: isFixedInputFieldWidth,
  isAutoFocus,
  isInHomePage,
  isButtonBottom,
  countryCode,
  shouldHaveBoxShadow,
}: PhoneInputProps) => {
  const { countryCode: countryCodeByHook } = useDetectCountry()
  const finalCountryCode = (countryCode || countryCodeByHook).toLowerCase()

  const {
    sizes: { sm },
  } = useResolution()

  return (
    <>
      <div
        className={classNames('flex w-full', {
          'flex-col space-y-2': isButtonBottom,
          'lg:space-x-2 md:flex-row': !isButtonBottom,
          'flex-col space-y-2 lg:space-y-0': isInHomePage,
        })}
      >
        <div>
          <PhoneInput
            inputStyle={{
              paddingLeft: '60px',
              width: isInHomePage && !sm ? '318px' : '100%',
            }}
            containerClass="font-sans text-lg"
            inputClass={classNames(
              'appearance-none w-full py-4 rounded-sm text-grey leading-tight focus:outline-none',
              {
                'lg:w-80': isFixedInputFieldWidth,
                'border border-grey-500': isInputFieldBorderGrey,
                'shadow-md': shouldHaveBoxShadow,
              }
            )}
            country={finalCountryCode}
            value={phone}
            searchStyle={{
              marginBottom: '2px',
              color: '#000',
            }}
            enableSearch
            countryCodeEditable={false}
            onChange={handlePhoneInputChange}
            onEnterKeyPress={handlePhoneInputFormSubmit}
            masks={{ in: '..... .....' }}
            inputProps={{
              'data-testid': 'phone-input',
              id: 'phone',
              name: 'phone',
              required: true,
              autoFocus: isAutoFocus,
              placeholder: t`Phone number`,
            }}
          />
        </div>
        <div className="flex flex-col">
          <button
            data-testid="book-button"
            className="h-full py-3 text-lg btn btn-orange font-600 md:ml-0 md:mt-0 lg:text-xl lg:w-auto lg:mt-0 lg:px-5 xl:px-10"
            onClick={handlePhoneInputFormSubmit}
          >
            {buttonText}
          </button>
        </div>
      </div>
      <div>
        {errors.phone && (
          <PhoneInputErrorMessage
            isErrorDarkRed={isErrorDarkRed}
            message={errors.phone}
          />
        )}
        {errors.nonFieldErrors && (
          <PhoneInputErrorMessage
            isErrorDarkRed={isErrorDarkRed}
            message={errors.nonFieldErrors}
          />
        )}
        {errors.detail && (
          <PhoneInputErrorMessage
            isErrorDarkRed={isErrorDarkRed}
            message={errors.detail}
          />
        )}
      </div>
    </>
  )
}

const PhoneInputErrorMessage = ({ isErrorDarkRed, message }) => (
  <div
    className={classNames('pb-5 -mb-8 text-sm lg:text-base font-400', {
      'text-red-100': !isErrorDarkRed,
      'text-red-200': isErrorDarkRed,
    })}
    data-testid="validation-error"
  >
    {message}
  </div>
)

export default PhoneInputContainer
